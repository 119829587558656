/*eslint no-loop-func: "off"*/
import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  from,
  fromPromise,
} from '@apollo/client'
import jwt_decode from 'jwt-decode'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'

import {
  AuthToken,
  REFRESH_TOKEN,
  RefreshTokenData,
  RefreshTokenVars,
} from './graphql/mutation'

const setAccessToken = (token: string) =>
  localStorage.setItem('accessToken', token)
const getAccessToken = () => localStorage.getItem('accessToken')
const getRefreshToken = () => localStorage.getItem('refreshToken') || ''

let isRefreshing = false
let pendingRequests: any = []

const resolvePendingRequests = () => {
  pendingRequests.map((callback: any) => callback())
  pendingRequests = []
}

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        switch (err.extensions!.code) {
          case 'UNAUTHENTICATED':
            let forward$

            if (!isRefreshing && getRefreshToken() !== '') {
              isRefreshing = true
              forward$ = fromPromise(
                client
                  .mutate<RefreshTokenData, RefreshTokenVars>({
                    mutation: REFRESH_TOKEN,
                    variables: {
                      refreshToken: getRefreshToken(),
                    },
                  })
                  .then(({ data }) => {
                    if (data?.refresh.accessToken) {
                      const { accessToken } = data.refresh
                      const { roles }: AuthToken = jwt_decode(accessToken)
                      if (!roles.length) roles.push('USER')
                      localStorage.setItem('roles', JSON.stringify(roles))
                      return setAccessToken(accessToken)
                    }
                  })
                  .then(() => resolvePendingRequests())
                  .catch(() => {
                    localStorage.clear()
                    pendingRequests = []
                    return
                  })
                  .finally(() => {
                    isRefreshing = false
                  }),
              )
            } else if (getRefreshToken() === '') {
              return
            } else if (isRefreshing) {
              localStorage.clear()
              sessionStorage.clear()
              pendingRequests = []
              return
            } else {
              forward$ = fromPromise(
                new Promise<void>((resolve) => {
                  pendingRequests.push(() => resolve())
                }),
              )
            }

            return forward$.flatMap(() => forward(operation))
          default:
            console.error(
              `[GraphQL error]: Message: ${err.message}, Location: ${err.locations}, Path: ${err.path}`,
            )
        }
      }
    }

    if (networkError) console.error(`[Network error]: ${networkError}`)
  },
)

const authLink = setContext((_, context) => {
  const accessToken = getAccessToken()

  if (accessToken !== null) {
    return {
      ...context,
      headers: {
        ...context.headers,
        authorization: `Bearer ${accessToken}`,
      },
    }
  }

  return context
})

const httpLink = createHttpLink()

const client = new ApolloClient({
  link: from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          agentCalls: {
            merge(_, incoming) {
              return incoming
            }
          }
        }
      }
    }
  }),
})

export default client
