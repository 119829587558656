import React, { FC, useState } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, useLocation } from 'react-router-dom'
import { useOrganization } from '../context/OrganizationContext'
import Navbar from 'react-bootstrap/Navbar'
import useRole from '../hooks/useRole'

export interface Props {
  isLoggedIn: boolean
}

const Container = styled.div`
  .top-nav {
    border-bottom: 1px solid var(--border-color);

    .navbar-brand {
      color: inherit;
      font-family: var(--headings-font-family);
      font-size: var(--h4-font-size);
      &:hover {
        color: inherit;
      }
    }

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
      .navbar {
        padding-bottom: 0;
      }

      .navbar-nav.main {
        width: 100%;

        .nav-item {
          margin-left: 0.25rem;
          margin-right: 0.25rem;

          &:first-child {
            margin-left: 0;
          }
          &:last-child {
            margin-right: 0;
          }

          .active {
            position: relative;
            :after {
              background-color: var(--secondary);
              bottom: -1px;
              content: '';
              display: block;
              height: 2px;
              position: absolute;
              width: 100%;
              left: 0;
            }
          }

          :hover {
            position: relative;
            :after {
              background-color: var(--secondary);
              bottom: -1px;
              content: '';
              display: block;
              height: 2px;
              position: absolute;
              width: 100%;
            }
          }
        }
      }
    }
  }
`

const NavBarItem: FC<{
  className?: string
  to: string
}> = ({ className, to, children }) => {
  const { pathname } = useLocation()

  const locationPaths = pathname.split('/')
  const toPaths = to.split('/')
  const active = locationPaths[1] === toPaths[1]

  return (
    <li className={`nav-item ${active ? 'active' : ''} ${className}`}>
      <Link className={`nav-link ${active ? 'active' : ''}`} to={to}>
        {children}
      </Link>
    </li>
  )
}

export const TopNav: FC<Props> = ({ isLoggedIn }) => {
  const [isNavCollapsed, setIsNavCollapsed] = useState(true)

  const { hasRole } = useRole()

  const { pathname } = useLocation()
  const locationPaths = pathname.split('/')
  const active = locationPaths[1]

  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed)
  const { organization, availableOrganizations } = useOrganization()

  return (
    <Container>
      <div className="top-nav">
        <Navbar expand="lg" variant="light" bg="light" className="flex-column">
          <div className="container flex-nowrap">
            <div className="navbar-brand text-truncate">
              <img
                src="/img/logo.svg"
                height="26"
                alt="Create a Call"
                className="d-inline-block align-text-top me-2"
              />
              {isLoggedIn && organization ? (
                <>
                  {organization.name}
                  {availableOrganizations.length > 1 && (
                    <Link
                      className="btn mx-2"
                      to={`/settings/organizations${!!active ? `?previous_path=${active}` : ''
                        }`}
                    >
                      <FontAwesomeIcon icon={['far', 'exchange']} />
                    </Link>
                  )}
                </>
              ) : (
                'Create a Call'
              )}
            </div>
            <button
              className="navbar-toggler"
              type="button"
              onClick={handleNavCollapse}
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse">
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  {isLoggedIn && (
                    <Link className="nav-link" to="/logout">
                      Logout
                    </Link>
                  )}
                </li>
              </ul>
            </div>
          </div>
          {isLoggedIn && (
            <div className="container pt-md-3">
              <div
                className={`${isNavCollapsed ? 'collapse' : ''
                  } navbar-collapse`}
              >
                <ul className="navbar-nav main me-auto mb-2 mb-lg-0">
                  {hasRole('MANAGER') && (
                    <NavBarItem to="/">
                      <FontAwesomeIcon icon={['far', 'tachometer']} /> Overview
                    </NavBarItem>
                  )}
                  {hasRole('MANAGER') && (
                    <NavBarItem to="/in-progress">
                      <FontAwesomeIcon icon={['far', 'phone']} /> In Progress
                    </NavBarItem>
                  )}
                  {hasRole('MANAGER') && (
                    <NavBarItem to="/contacts">
                      <FontAwesomeIcon icon={['far', 'address-card']} />{' '}
                      Contacts
                    </NavBarItem>
                  )}
                  {hasRole('ADMIN') && (
                    <NavBarItem to="/sources">
                      <FontAwesomeIcon icon={['far', 'chart-network']} />{' '}
                      Sources
                    </NavBarItem>
                  )}
                  {hasRole('ADMIN') && (
                    <NavBarItem to="/agents">
                      <FontAwesomeIcon icon={['far', 'headset']} /> Agents
                    </NavBarItem>
                  )}
                  {hasRole('ADMIN') && (
                    <NavBarItem className="ms-lg-auto" to="/settings">
                      <FontAwesomeIcon icon={['far', 'cog']} /> Settings
                    </NavBarItem>
                  )}
                </ul>
              </div>
            </div>
          )}
        </Navbar>
      </div>
    </Container>
  )
}
