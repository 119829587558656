import { useQuery } from '@apollo/client'
import React from 'react'
import { GET_ME, MeData } from '../graphql/query'

interface IUserContext {
  isLoggedIn: () => boolean
}

export const UserContext = React.createContext<
  IUserContext | undefined
>(undefined)

export const UserProvider: React.FunctionComponent = ({ children }) => {
  const { startPolling, stopPolling } = useQuery<MeData>(GET_ME)

  const isLoggedIn = () => {
    if (localStorage.getItem('accessToken') !== null && localStorage.getItem('accessToken') !== "") {
      startPolling(30000)
      return true
    }

    stopPolling()
    localStorage.clear()
    sessionStorage.clear()

    return false
  }

  return <UserContext.Provider value={{
    isLoggedIn: isLoggedIn,
  }}>
    {children}
  </UserContext.Provider>
}

export const useUser = () => {
  const context = React.useContext(UserContext)
  if (!context) {
    throw new Error('useUser must be used within a UserProvider')
  }
  return context
}
