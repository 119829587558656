import React, { FC } from 'react'
import PulseLoader from 'react-spinners/PulseLoader'
import RingLoader from 'react-spinners/RingLoader'

interface Props {
  color?: string
  type?: 'pulse' | 'ring'
  size?: number
}

const LoadingSpinner: FC<Props> = ({
  color = 'black',
  type = 'pulse',
  size = 14,
}) =>
  type === 'pulse' ? (
    <PulseLoader color={color} size={size} />
  ) : (
    <RingLoader color={color} size={size} />
  )

export default LoadingSpinner
