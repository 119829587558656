import React, { FC, ReactNode } from 'react'

import { ThemeProvider, createGlobalStyle, css } from 'styled-components'

interface Theme {
  colors: {
    [key: string]: string
  }
  breakpoints: {
    xs: string
    sm: string
    md: string
    lg: string
    xl: string
  }
}

export const defaultTheme: Theme = {
  colors: {
    primary: '#092d4f',
    secondary: '#f5543d',
    buttonHover: '#082643',
    linkHover: '#07243f',
    activeButtonBg: '#E8ECEF',
    buttonBg: '#F9F9FA',
    buttonBorder: '#dee2e6',
  },
  breakpoints: {
    xs: '576px',
    sm: '768px',
    md: '992px',
    lg: '1200px',
    xl: '1400px',
  },
}

export const defaultTransition = css`
  transition: all 0.2s ease-in-out;
`

export const defaultShadow = css`
  box-shadow: rgba(22, 22, 30, 0.2) 0px 7px 29px 0px;
`

type Props = {
  children: ReactNode
}

const GlobalStyle = createGlobalStyle`
    :root{
      --bs-link-hover-color: ${defaultTheme.colors.linkHover}!important;
    }
 
    body {
      overflow-y: scroll;
    }

    body .bg-primary{
      background-color: ${defaultTheme.colors.primary} ;
      border-color: ${defaultTheme.colors.primary} ;
      :hover, :focus{
        background-color: ${defaultTheme.colors.buttonHover} !important;
        border-color: ${defaultTheme.colors.buttonHover} !important;
      }
    } 
    a:hover{
      color: ${defaultTheme.colors.linkHover} !important;
    }
  `

const Provider: FC<Props> = ({ children }) => {
  return (
    <ThemeProvider theme={defaultTheme}>
      <>
        <GlobalStyle />
        {children}
      </>
    </ThemeProvider>
  )
}

export default Provider
