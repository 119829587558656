import { IconDefinition, library } from '@fortawesome/fontawesome-svg-core'
import {
  faCalendarAlt as faCalendarAltSolid,
  faChevronRight,
  faCircle,
  faEllipsis,
  faEye as faEyeSolid,
  faPlay as faPlaySolid,
  faStop,
} from '@fortawesome/pro-solid-svg-icons'
import {
  faAddressCard,
  faChartNetwork,
  faCheck,
  faCog,
  faCopy,
  faEnvelope,
  faExchange,
  faHeadset,
  faMinus,
  faPhoneRotary,
  faTachometer,
  faSync,
  faPhone,
  faPlay,
  faPause,
  faPhoneSlash,
  faPhoneMissed,
  faPhoneArrowUpRight,
  faPhoneArrowDownLeft,
  faVoicemail,
  faCalendarAlt,
} from '@fortawesome/pro-regular-svg-icons'

library.add(
  faAddressCard as IconDefinition,
  faCalendarAlt as IconDefinition,
  faCalendarAltSolid as IconDefinition,
  faChartNetwork as IconDefinition,
  faCheck as IconDefinition,
  faCircle as IconDefinition,
  faCog as IconDefinition,
  faCopy as IconDefinition,
  faEllipsis as IconDefinition,
  faEnvelope as IconDefinition,
  faEyeSolid as IconDefinition,
  faExchange as IconDefinition,
  faHeadset as IconDefinition,
  faMinus as IconDefinition,
  faPhoneRotary as IconDefinition,
  faTachometer as IconDefinition,
  faStop as IconDefinition,
  faSync as IconDefinition,
  faChevronRight as IconDefinition,
  faPhone as IconDefinition,
  faPlay as IconDefinition,
  faPlaySolid as IconDefinition,
  faPause as IconDefinition,
  faPhoneSlash as IconDefinition,
  faPhoneMissed as IconDefinition,
  faPhoneArrowUpRight as IconDefinition,
  faPhoneArrowDownLeft as IconDefinition,
  faVoicemail as IconDefinition,
)
