import { useState, useEffect, Dispatch, SetStateAction } from 'react'

function getStorageValue<T>(key: string, defaultValue: T): T {
  const saved = localStorage.getItem(key)
  if (!saved) {
    return defaultValue
  }
  try {
    const initial = JSON.parse(saved)
    return initial || defaultValue
  } catch (error) {
    return defaultValue
  }
}

export function useLocalStorage<T>(
  key: string,
  defaultValue: T,
): [T, Dispatch<SetStateAction<T>>] {
  const [value, setValue] = useState(() =>
    getStorageValue<T>(key, defaultValue),
  )

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value))
  }, [key, value])

  return [value, setValue]
}
