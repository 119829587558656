import { gql } from '@apollo/client'

export interface AuthenticateData {
  authenticate: {
    accessToken: string
    refreshToken: string
  }
}
export interface AuthenticateVars {
  username: string
  password: string
}

export const AUTHENTICATE = gql`
  mutation ($username: String!, $password: String!) {
    authenticate(username: $username, password: $password) {
      accessToken
      refreshToken
    }
  }
`

export interface RefreshTokenData {
  refresh: {
    accessToken: string
  }
}
export interface RefreshTokenVars {
  refreshToken: string
}

export type AuthToken = {
  email_address: string
  exp: number
  iat: number
  iss: string
  roles: string[]
  organization_ids: string[]
  sub: string
}

export const REFRESH_TOKEN = gql`
  mutation ($refreshToken: String!) {
    refresh(refreshToken: $refreshToken) {
      accessToken
    }
  }
`
