import styled, { css } from 'styled-components'
import { Box } from '../../components/UI/Box'
import Button from 'react-bootstrap/Button'
import BSTable from 'react-bootstrap/Table'

import { defaultTransition } from '../../context/StyleProvider'

export const TopBar = styled(Box)`
  flex-direction: column;
  ${Box} {
    flex-wrap: wrap;
  }
  margin-bottom: 32px;
  @media (min-width: 1200px) {
    .bottom-row {
      max-width: calc(50% - 8px);
    }
  }
`

export const TitleAndDropdown = styled(Box)`
  position: relative;
  gap: 8px;
  margin-bottom: 4px;
  justify-content: space-between;
  span.bold {
    font-weight: 600;
  }
  ${Box} {
    gap: 8px;
  }
  .dropdown-item.active,
  .dropdown-item:active {
    background-color: #f8f9fa;
    color: #000;
  }
  .dropdown-item:hover,
  .dropdown-item:focus {
    text-decoration: none;
  }
`

export const AllStatsContainer = styled(Box)`
  flex-direction: column;
  margin-top: 1.5rem;
`

export const Row = styled(Box)`
  margin: 16px 0;
  column-gap: 16px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export const TabContainer = styled(Box)`
  .card,
  .top-row {
    width: 100%;
  }
  .toggle-container {
    .tab-badge-pill {
      :hover {
        cursor: pointer;
        filter: brightness(0.9);
      }
      ${defaultTransition}
    }
  }
`

export const Sorter = styled(Box)`
  margin: 16px 0;
  flex-direction: column;
  span {
    margin-bottom: 4px;
  }
  input,
  select {
    width: 100%;
    min-width: 200px;
  }
`

export const StatContainer = styled(Box)`
  flex-direction: column;
  position: relative;
  flex: unset;
  width: auto;
  span.amount {
    font-size: 48px;
    line-height: 1;
  }
  span.metric {
    margin-top: 4px;
  }
  span.low-opacity {
    opacity: 0.6;
  }
  .gain {
    color: var(--bs-success);
  }
  .loss {
    color: var(--bs-danger);
  }
  .neutral {
    opacity: 0.4;
  }

  span.rate {
    position: absolute;
    margin-left: 92px;
    margin-bottom: 50px;
    padding: 4px 10px;
    font-size: 12px;
    font-weight: bold;
    opacity: 0.8;
  }
  div.pos-rel {
    position: relative;
  }
  @media (max-width: 768px) {
    margin-bottom: 16px;
    text-align: center;
    ${Box} {
      margin: auto;
      align-items: center;
      justify-content: center;
    }
  }
`

export const DateContainer = styled.div`
  input {
    cursor: pointer;
  }
  @media screen and (min-width: 992px) {
    position: absolute;
    top: 32px;
    right: 0;
    width: 440px;
  }
`

export const LoadingContainer = styled(Box)`
  flex: 1;
  min-height: 200px;
  justify-content: center;
  align-items: center;
`

export const ColorSpan = styled.span<{ color: string }>`
  color: ${({ color }) => color};
`

export const ChangeSpan = styled.span<{ change: number }>`
  margin-left: 8px;
  font-size: 10px;
  ${({ change }) =>
    change === 0
      ? 'opacity: 0.4;'
      : `color: ${change >= 0 ? 'var(--bs-success)' : 'var(--bs-danger)'};
  `}
`

export const RefreshButton = styled(Button)`
  display: flex;
  gap: 8px;
  .spinning-container.active {
    animation: spin 1s linear infinite;
  }
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`

export const LoadingSpinnerContainer = styled(Box)`
  height: 200px;
`

export const TableContainer = styled(Box)`
  overflow: hidden;
  .scroll-indicator {
    display: flex;
    align-items: center;
    background: white;
    padding: 8px;
    position: absolute;
    top: 197px;
    right: 8px;
    ${defaultTransition}
    svg {
      ${defaultTransition}
    }
    svg:hover {
      cursor: pointer;
      transform: scale(1.05);
      filter: brightness(1.5);
    }
  }
  .scroll-indicator.hide {
    opacity: 0;
    pointer-events: none;
  }
  @media screen and (max-width: 768px) {
    .scroll-indicator {
      display: none;
    }
    td.source {
      font-size: 0.75rem;
    }
  }
`

export const Scroller = styled.div`
  overflow: auto;
  flex: 4;
  scroll-behaviour: smooth;
`

export const Table = styled(BSTable)<{
  $scrollableTable?: boolean
  $tableWidth: number
}>`
  position: relative;
  flex: ${({ $scrollableTable }) => ($scrollableTable ? 5 : 1)};
  width: 100%;
  margin-top: 32px;
  thead {
    border-bottom-color: var(--table-top-border-color);
  }
  .bottom-border {
    border-bottom: 1px solid var(--table-top-border-color);
  }
  th {
    font-weight: bold;
    ${({ $scrollableTable }) =>
      $scrollableTable &&
      css`
        min-width: 33%;
      `}
  }
  tr {
    vertical-align: middle;
    height: 48px;
  }
  td {
    min-width: ${({ $scrollableTable, $tableWidth }) =>
      $scrollableTable ? $tableWidth / 5 : 120}px;
  }
  @media screen and (max-width: 768px) {
    tr {
      height: 53px;
    }
    ${({ $scrollableTable }) =>
      $scrollableTable &&
      css`
        min-width: 70%;
      `}
    td {
      min-width: ${({ $scrollableTable, $tableWidth }) =>
        $scrollableTable ? $tableWidth - 150 : 150}px;
    }
  }
`

export const NoData = styled(Box)`
  height: 200px;
`
