import React from 'react'
import { LoadingContainer } from './Overview/styles'
import LoadingSpinner from '../components/UI/LoadingSpinner'

export interface Props {}

const Loading: React.FunctionComponent<Props> = () => {
  return (
    <LoadingContainer>
      <LoadingSpinner />
    </LoadingContainer>
  )
}

export default Loading
