import { AuthToken } from '../graphql/mutation'
import jwt_decode from 'jwt-decode'

export type Role = 'SUPER_ADMIN' | 'ADMIN' | 'MANAGER' | 'USER'

const useRole = () => {
  const accessToken = localStorage.getItem('accessToken') || ''
  if (!accessToken) return { roles: [], hasRole: (_: Role) => false }

  const { roles }: AuthToken = jwt_decode(accessToken)

  const hasRole = (role: Role) =>
    roles.map((r) => r.toLowerCase()).includes(role.toLowerCase())

  return { roles, hasRole }
}

export default useRole
