import { gql } from '@apollo/client'
import { User } from './user'

export interface MeData {
  me: User
}

export const GET_ME = gql`
  query {
    me {
      emailAddress
      organizations {
        id
        name
        mailboxPrefix
      }
    }
  }
`
