import React from 'react'
import { Outlet, RouteObject } from 'react-router'
import { Navigate } from 'react-router-dom'
import { Page } from './components/Page'
import { Organization } from './graphql/query/organization'
import { Role } from './hooks/useRole'

const Contact = React.lazy(() => import('./pages/Contact'))
const ContactList = React.lazy(() => import('./pages/ContactList'))
const Contacts = React.lazy(() => import('./pages/Contacts'))
const Endpoint = React.lazy(() => import('./pages/Endpoint'))
const EndpointList = React.lazy(() => import('./pages/EndpointList'))
const Endpoints = React.lazy(() => import('./pages/Endpoints'))
const InProgress = React.lazy(() => import('./pages/InProgress'))
const Login = React.lazy(() => import('./pages/Login'))
const Logout = React.lazy(() => import('./pages/Logout'))
const Mailbox = React.lazy(() => import('./pages/Mailbox'))
const MailboxList = React.lazy(() => import('./pages/MailboxList'))
const Mailboxes = React.lazy(() => import('./pages/Mailboxes'))
const NotFound = React.lazy(() => import('./pages/NotFound'))
const Overview = React.lazy(() => import('./pages/Overview'))
const Organizations = React.lazy(() => import('./pages/Organizations'))
const Settings = React.lazy(() => import('./pages/Settings'))
const Source = React.lazy(() => import('./pages/Source'))
const SourceList = React.lazy(() => import('./pages/SourceList'))
const Sources = React.lazy(() => import('./pages/Sources'))

const Page404 = () => (
  <Page>
    <NotFound />
  </Page>
)

function routes(
  isLoggedIn: boolean = false,
  hasRole: (role: Role) => boolean,
  organizationIds: Organization[],
): RouteObject[] {
  return [
    {
      path: '/',
      element: isLoggedIn ? (
        hasRole('MANAGER') ? (
          <Overview />
        ) : (
          <InProgress />
        )
      ) : (
        <Navigate to="/login" state={{ from: '/' }} />
      ),
    },
    {
      path: 'in-progress',
      element: isLoggedIn ? (
        // hasRole('MANAGER') ? (
        <Outlet />
      ) : (
        // ) : (
        //   <Page404 />
        // )
        <Navigate to="/login" state={{ from: 'in-progress' }} />
      ),
      children: [
        { path: '', element: <InProgress /> },
        { path: ':id', element: <Contact /> },
      ],
    },
    {
      path: 'contacts',
      element: isLoggedIn ? (
        hasRole('MANAGER') ? (
          <Contacts />
        ) : (
          <Page404 />
        )
      ) : (
        <Navigate to="/login" state={{ from: 'contacts' }} />
      ),
      children: [
        { path: '', element: <ContactList /> },
        { path: ':id', element: <Contact /> },
      ],
    },
    { path: 'login', element: isLoggedIn ? <Navigate to="/" /> : <Login /> },
    { path: 'logout', element: <Logout /> },
    {
      path: 'settings',
      element: isLoggedIn 
        ? <Settings />
        : <Navigate to="/login" state={{ from: 'settings' }} />,
      children: [
        {
          path: 'mailboxes',
          element: hasRole('ADMIN')
            ? <Mailboxes />
            : <Page404 />,
          children: [
            { path: '', element: <MailboxList /> },
            { path: ':id', element: <Mailbox /> },
          ],
        },
        {
          path: 'endpoints',
          element: hasRole('ADMIN')
            ? <Endpoints />
            : <Page404 />,
          children: [
            { path: '', element: <EndpointList /> },
            { path: ':id', element: <Endpoint /> },
          ],
        },
        {
          path: 'organizations',
          element: organizationIds.length > 0
            ? <Organizations />
            : <Page404 />,
        },
      ],
    },
    {
      path: 'sources',
      element: isLoggedIn ? (
        hasRole('ADMIN') ? (
          <Sources />
        ) : (
          <Page404 />
        )
      ) : (
        <Navigate to="/login" state={{ from: 'sources' }} />
      ),
      children: [
        { path: '', element: <SourceList /> },
        { path: ':id', element: <Source /> },
      ],
    },
    {
      path: '*',
      element: isLoggedIn ? (
        <Page404 />
      ) : (
        <Navigate to="/login" state={{ from: '/' }} />
      ),
    },
  ]
}

export default routes
