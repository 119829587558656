import { useQuery } from '@apollo/client'
import { createContext, useContext, useEffect } from 'react'

import { GET_ME, MeData } from '../graphql/query'
import { Organization } from '../graphql/query/organization'
import { useLocalStorage } from '../hooks/LocalStorage'
import { useSessionStorage } from '../hooks/SessionStorage'

export const OrganizationContext = createContext<
  IOrganizationContext | undefined
>(undefined)

interface IOrganizationContext {
  organization: Organization | null
  availableOrganizations: Organization[]
  setOrganization: (organization: Organization) => void
}

export const OrganizationProvider: React.FunctionComponent = ({ children }) => {
  const [currentOrganization, setCurrentOrganization] =
    useSessionStorage<Organization | null>('currentOrganization', null)

  const [organizationIndex, setOrganizationIndex] = useLocalStorage<number>(
    'organizationIndex',
    0,
  )

  const render = (value: IOrganizationContext) => (
    <OrganizationContext.Provider value={value}>
      {children}
    </OrganizationContext.Provider>
  )

  const setOrganization = (organization: Organization) =>
    setCurrentOrganization(organization)

  const { data, loading } = useQuery<MeData>(GET_ME)

  useEffect(() => {
    const allOrganizations = data?.me?.organizations || []

    if (!currentOrganization) {
      setCurrentOrganization(allOrganizations[organizationIndex])
      return
    }

    document.title = currentOrganization.name

    const orgIndex = allOrganizations.findIndex(
      (org) => org.id === currentOrganization.id,
    )

    if (orgIndex > -1) setOrganizationIndex(orgIndex)
  }, [
    currentOrganization,
    data,
    setOrganizationIndex,
    organizationIndex,
    setCurrentOrganization,
  ])

  if (!loading && data?.me) {
    return render({
      organization:
        currentOrganization || data.me.organizations[organizationIndex],
      availableOrganizations: data.me.organizations,
      setOrganization,
    })
  }

  return render({
    organization: null,
    availableOrganizations: [],
    setOrganization,
  })
}

export const useOrganization = () => {
  const context = useContext(OrganizationContext)
  if (context === undefined) {
    throw new Error(
      'useOrganization must be used within a OrganizationProvider',
    )
  }
  return context
}
