import React from 'react'
import { Link } from 'react-router-dom'

interface BreadcrumbItem {
  title: string
  to?: string
}
export interface Props {
  breadcrumbs: BreadcrumbItem[]
}

export const PageBreadcrumbs: React.FunctionComponent<Props> = ({
  breadcrumbs,
}) => {
  if (breadcrumbs.length === 0) {
    return null
  }

  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        {breadcrumbs.map((breadcrumb: BreadcrumbItem, idx: number) => (
          <li
            key={idx}
            className={`breadcrumb-item ${!breadcrumb.to ? 'active' : ''}`}
          >
            {breadcrumb.to && (
              <Link to={breadcrumb.to}>{breadcrumb.title}</Link>
            )}
            {!breadcrumb.to && <>{breadcrumb.title}</>}
          </li>
        ))}
      </ol>
    </nav>
  )
}
