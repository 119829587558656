import { ApolloProvider } from '@apollo/client'
import React, { FC, Suspense } from 'react'
import { useRoutes } from 'react-router-dom'

import client from './ApolloClient'
import routes from './routes'
import Loading from './pages/Loading'

import { Page } from './components/Page'
import { TopNav } from './components/TopNav'
import { OrganizationProvider, useOrganization } from './context/OrganizationContext'
import StyleProvider from './context/StyleProvider'

import './bootstrap.scss'
import useRole from './hooks/useRole'
import { useUser, UserProvider } from './context/UserContext'

const Main: FC = () => {
  const { isLoggedIn } = useUser()
  const { hasRole } = useRole()
  const { availableOrganizations } = useOrganization()
  const element = useRoutes(routes(isLoggedIn(), hasRole, availableOrganizations))
  return (
    <StyleProvider>
      <TopNav isLoggedIn={isLoggedIn()} />
      <Suspense
        fallback={
          <Page>
            <Loading />
          </Page>
        }
      >
        {element}
      </Suspense>
    </StyleProvider>
  )
}

const App: FC = () => {
  return (
    <ApolloProvider client={client}>
      <UserProvider>
        <OrganizationProvider>
          <Main />
        </OrganizationProvider>
      </UserProvider>
    </ApolloProvider>
  )
}

export default App
