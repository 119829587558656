import React from 'react'
import Container from 'react-bootstrap/Container'
import {
  PageBreadcrumbs,
  Props as PageBreadcrumbProps,
} from './PageBreadcrumbs'

export interface Props {
  breadcrumbs?: PageBreadcrumbProps['breadcrumbs']
  className?: string
}

export const Page: React.FunctionComponent<Props> = ({
  breadcrumbs,
  children,
  className,
}) => {
  return (
    <Container className={`page container py-4 ${className || ''}`}>
      {breadcrumbs && <PageBreadcrumbs breadcrumbs={breadcrumbs} />}
      {children}
    </Container>
  )
}
