import styled, { css } from 'styled-components'

export const Box = styled.div<{
  direction?: string | null
  justify?: string | null
  align?: string | null
  flex?: number | 'none' | 'auto' | 'unset' | null
  marginTop?: number | null
  marginBottom?: number | null
  wrap?: string | null
  zIndex?: number
  relative?: boolean
  center?: boolean
  gap?: number
  columnGap?: number
  rowGap?: number
}>`
  display: flex;
  justify-content: ${({ justify }) => justify ?? 'flex-start'};
  align-items: ${({ align }) => align ?? 'flex-start'};
  flex-direction: ${({ direction }) => (direction ? direction : 'row')};
  flex: ${({ flex }) => flex ?? 1};
  ${({ wrap }) => (wrap ? `flex-wrap:${wrap};` : '')};
  ${({ zIndex }) => (zIndex ? `z-index:${zIndex};` : '')};
  ${({ marginTop }) => (marginTop ? `margin-top:${marginTop}px;` : '')};
  ${({ marginBottom }) =>
    marginBottom ? `margin-bottom:${marginBottom}px;` : ''};
  width: 100%;
  ${({ relative }) =>
    relative &&
    css`
      position: relative;
    `}
  ${({ center }) =>
    center &&
    css`
      justify-content: center;
      align-items: center;
    `}  
  ${({ gap }) =>
    gap &&
    css`
      gap: ${gap}px;
    `}
  ${({ columnGap }) =>
    columnGap &&
    css`
      column-gap: ${columnGap}px;
    `}
  ${({ rowGap }) =>
    rowGap &&
    css`
      row-gap: ${rowGap}px;
    `}
`
